<template>
  <section class="xa-view" v-loading="isPostLoading">
    <titleFilterBarHead
      :isLoading="isLoading"
      :parent="parent"
      :title="page.title"
    />
    <div class="xa-flex xa-container xa-bg-white" style="margin-top:8px">
      <el-form
        v-if="!isLoading"
        class="xa-form__wrap"
        ref="form"
        label-position="left"
        :rules="rules"
        :model="form"
        label-width="160px"
      >
        <el-form-item label="活动名称" prop="title">
          <el-input
            v-model="form.title"
            placeholder="请输入活动名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="小标题" prop="sub_title">
          <el-input
            v-model="form.sub_title"
            placeholder="请输入小标题"
          ></el-input>
        </el-form-item>
        <el-form-item label="活动海报" prop="img">
          <UploadCom
            style="padding-top:0;"
            mode="edit"
            v-model="form.img"
            max="1"
            :size="[325, 180]"
          />
          <div class="xa-txt-12 xa-color-warning">图片尺寸要求:750px*360px</div>
        </el-form-item>
        <el-form-item label="活动时间" prop="date">
          <el-date-picker
            v-model="form.date"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy/MM/dd HH:mm:ss"
            align="right"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="活动地址链接" prop="url">
          <el-input
            v-model="form.url"
            placeholder="如需跳转到指定地址链接，请输入！"
          />
          <span class="xa-txt-999">为空时，系统会自动生成本站活动地址。</span>
        </el-form-item>
        <el-form-item label="是否开启活动" prop="status">
          <el-switch v-model="form.status" />
        </el-form-item>
        <el-form-item v-show="!form.url" label="活动详情" prop="content">
          <Ueditor
            v-model="form.content"
            @addProduct="onAddUeditorProduct"
            :appendProduct="curAddUeditorProduct"
          />
        </el-form-item>
      </el-form>
    </div>
    <div
      class="page-btn xa-bg-white"
      style="padding:8px 4px;border-top:1px solid #e4e4e4;"
    >
      <el-button
        v-show="form.guid"
        type="primary"
        size="mini"
        @click="submitForm('form')"
        >保存并刷新</el-button
      >
      <el-button type="primary" size="mini" @click="submitForm('form', true)"
        >保存并返回</el-button
      >
    </div>
    <el-dialog
      class="product-dialog"
      title="选择产品"
      :visible.sync="isShowProductTable"
    >
      <productTable
        v-show="isShowProductTable"
        @close="isShowProductTable = false"
        @submit="onAddProductSubmit"
      />
    </el-dialog>
  </section>
</template>
<script>
import { saveProduct, getProductActivity } from '@target/apis/mall'
import recodeLastPath from '@/controllers/recodeLastPath'
const STORAGE_KEY = 'MALL_ACTIVITY'
export default {
  components: {
    productTable: () => import('./productTable'),
    titleFilterBarHead: () => import('@/components/comHead/FilterBarHead'),
    UploadCom: () => import('@/components/xa-form/coms/FormUpload.vue'),
    Ueditor: () => import('@/components/ueditor/MallUeditor'),
  },
  data() {
    return {
      isShowProductTable: false,
      curAddUeditorProduct: '', // ueditor添加的商品
      addProductTableType: '', // table/ueditor
      isLoading: true,
      isPostLoading: false,
      page: {
        title: '表单',
      },
      parent: {
        // 上一个页面得到信息
        path: '',
        title: '',
      },
      form: {
        guid: '',
        title: '',
        sub_title: '',
        img: [],
        url: '',
        date: [],
        status: false,
        content: '',
        products: [],
      },
      rules: {
        title: [{ required: true, message: '请输入活动名称', trigger: 'blur' }],
        img: [
          {
            type: 'array',
            required: true,
            message: '请上传相关照片',
          },
        ],
        date: [
          {
            type: 'array',
            required: true,
            message: '请选择下时间',
          },
        ],
        products: [
          {
            type: 'array',
            required: true,
            message: '请选择下产品',
          },
        ],
      },
    }
  },
  methods: {
    onDeleteProducts(index) {
      this.form.products.splice(index, 1)
    },
    onAddTableProduct() {
      this.addProductTableType = 'table'
      this.isShowProductTable = true
    },
    onAddProductSubmit(items) {
      if (this.addProductTableType === 'table') {
        let newItems = items.filter((item) => {
          const result = this.form.products.find(
            (products) => products.guid === item.guid
          )
          return !result
        })
        this.form.products = this.form.products.concat(newItems)
      } else {
        this.curAddUeditorProduct = JSON.stringify(items)
      }
    },
    onAddUeditorProduct() {
      this.addProductTableType = 'ueditor'
      this.isShowProductTable = true
    },
    submitForm(formName, goBack) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.isPostLoading = true
          let params = JSON.parse(JSON.stringify(this.form))
          params.status = params.status ? 1 : 2
          params.start_at = params.date[0]
          params.end_at = params.date[1]
          params.img = params.img[0]
          params.product_guids = params.products
            .map((item) => item.guid)
            .join(',')
          delete params.products
          delete params.date
          this.$actionWithConfirm(saveProduct(params)).then(
            () => {
              this.isPostLoading = false
              this.$message.success('保存成功')
              goBack && this.$router.go(-1)
            },
            () => {
              this.isPostLoading = false
            }
          )
        } else {
          this.$notify.error({
            title: '请检查表单',
            message: '有部分字段没有按照要求填写',
          })
          return false
        }
      })
    },
  },
  beforeRouteEnter(to, from, next) {
    /* 如果是导航点击的跳转地址 着直接打开 */
    if (to.query.nav) {
      next()
    } else {
      next((vm) => {
        const parent = recodeLastPath(from, vm, STORAGE_KEY)
        if (parent) vm.parent = parent
      })
    }
  },
  async mounted() {
    let title = '新建活动'
    if (this.$route.query.params && this.$route.query.params) {
      let params = JSON.parse(this.$route.query.params)
      const data = await getProductActivity(params.guid)
      data.date = [data.start_at, data.end_at]
      data.status = parseInt(data.status) === 1
      data.img = [data.img]
      data.guid = params.guid
      Object.assign(this.form, data)
      title = '活动编辑'
    }
    this.page.title = title
    this.isLoading = false
    //   this.createFn('diyform_mallActivity.json').then(data => {
    //     data.date = [data.start_at, data.end_at]
    //     data.status = parseInt(data.status) === 1
    //     Object.assign(this.form, data)
    //     this.page.title = '管理活动'
    //     this.isLoading = false
    //   })
  },
}
</script>
<style lang="scss" scoped>
.xa-form__wrap {
  padding: 32px;
  max-width: 960px;
}
.products-add-btn {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
/deep/.product-dialog .el-dialog__body {
  padding: 0 20px;
}
/deep/.product-dialog .el-dialog{
  min-width: 720px;
}
</style>
